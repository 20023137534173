import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-886930fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-steps--container" }
const _hoisted_2 = { class: "c-steps--item" }
const _hoisted_3 = { class: "c-steps-item--image" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "c-steps-item--data" }
const _hoisted_6 = { class: "c-steps--item" }
const _hoisted_7 = { class: "c-steps-item--image" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "c-steps-item--data" }
const _hoisted_10 = { class: "c-steps--item" }
const _hoisted_11 = { class: "c-steps-item--image" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "c-steps-item--data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t('wearables.stepsCard.title'),
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.iconsUrlBase + 'icon-steps.svg',
            alt: ""
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.stepScore?.value ? Math.round(_ctx.stepScore?.value) : '-'), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.stepsCard.steps')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            src: _ctx.iconsUrlBase + 'icon-distance.svg',
            alt: ""
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_ctx.distanceScore?.value ? Math.round((_ctx.distanceScore?.value / 1000) * 100) / 100 : '-'), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.stepsCard.km')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("img", {
            src: _ctx.iconsUrlBase + 'icon-calories.svg',
            alt: ""
          }, null, 8, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(_ctx.caloriesScore?.value ? Math.round(_ctx.caloriesScore?.value) : '-'), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.stepsCard.kcal')), 1)
        ])
      ])
    ])
  ], 64))
}